/*//Copy this css*/
.navbar-light .navbar-nav .nav-link {
  color: rgb(64, 64, 64);
}

.btco-menu li > a {
  padding: 10px 15px;
  color: #000;
}

.btco-menu .active a:focus,
.btco-menu li a:focus,
.navbar > .show > a:focus {
  background: transparent;
  outline: 0;
}


.dropdown-menu .show > .dropdown-toggle::after {
  transform: rotate(-90deg);
}
