/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.field-required::after {
  content: " (*)";
  color: red;
}

.field-validation-error {
  color: red;
  margin-left: 2px;
}

.action-buttons-2 {
  min-width: 110px !important;
}

.action-buttons-3 {
  min-width: 150px !important;
}

.min-width-150 {
  min-width: 150px;
}
.min-width-250 {
  min-width: 250px;
}


.overflow-hidden {
  overflow: hidden;
}


i.material-icons + span {
  vertical-align: middle;
}

i.material-icons {
  vertical-align: middle;
}

i.fas {
  vertical-align: middle;
}

a.underline {
  text-decoration: underline !important;
}

a {
  cursor: pointer !important;
}

.disabled {
  color: gray !important;
}

input.date {
  background-color: white !important;
}


/*
	Hielo by TEMPLATED
	templated.co @templatedco
	Released for free under the Creative Commons Attribution 3.0 license (templated.co/license)
*/

/* Reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  position: relative;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Containers */

.container {
  margin-left: auto;
  margin-right: auto;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {

  html, body {
    min-width: 320px;
  }

}

body {
  background: #fff;
}

body.is-loading *, body.is-loading *:before, body.is-loading *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */

html {
  font-size: 13pt;
}

@media screen and (max-width: 1680px) {

  html {
    font-size: 11pt;
  }

}

@media screen and (max-width: 1280px) {

  html {
    font-size: 11pt;
  }

}

@media screen and (max-width: 980px) {

  html {
    font-size: 12pt;
  }

}

@media screen and (max-width: 736px) {

  html {
    font-size: 12pt;
  }

}

@media screen and (max-width: 480px) {

  html {
    font-size: 12pt;
  }

}

html {
  min-height: 100%;
  display: flex;
}

body {
  flex: 1;
}

html, body {
  background-color: #fff;
  color: #444;
  min-width: 100%;
}

body, input, select, textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.65;
}


strong, b {
  font-weight: 600;
}

em, i {
  font-style: italic;
}

p {
  margin: 0 0 2rem 0;
}

p.special {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 300;
  margin: 0 0 .5rem 0;
  padding: 0 0 1rem 0;
  letter-spacing: .25rem;
}

p.special:after {
  content: '';
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.125);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.7rem;
}

sub {
  font-size: 0.8rem;
  position: relative;
  top: 0.5rem;
}

sup {
  font-size: 0.8rem;
  position: relative;
  top: -0.5rem;
}

blockquote {
  border-left: solid 4px;
  font-style: italic;
  margin: 0 0 2rem 0;
  padding: 0.5rem 0 0.5rem 2rem;
}

code {
  border-radius: 2px;
  border: solid 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0.25rem;
  padding: 0.25rem 0.65rem;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0 2rem 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1rem 1.5rem;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px;
  margin: 2rem 0;
}

hr.major {
  margin: 3rem 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

input, select, textarea {
  color: #555;
}

a {
  color: #8a4680;
}

strong, b {
  color: #555;
}

h1, h2, h3, h4, h5, h6 {
  color: #555;
}

blockquote {
  border-left-color: rgba(144, 144, 144, 0.25);
}

code {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}

hr {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}

/* Box */

.box {
  margin-bottom: 2rem;
  background: #FFF;
}

.box .image.fit {
  margin: 0;
  border-radius: 0;
}

.box .image.fit img {
  border-radius: 0;
}

.box header h2 {
  margin-bottom: 2rem;
}

.box header p {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 300;
  margin: 0 0 .25rem 0;
  padding: 0 0 .75rem 0;
  letter-spacing: .25rem;
}

.box header p:after {
  content: '';
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.125);
}

.box .content {
  padding: 3rem;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

@media screen and (max-width: 736px) {

  .box .content {
    padding: 2rem;
  }

}

.box {
  border-color: rgba(144, 144, 144, 0.25);
}

::-webkit-input-placeholder {
  color: #bbb !important;
}

:-moz-placeholder {
  color: #bbb !important;
}

::-moz-placeholder {
  color: #bbb !important;
}

:-ms-input-placeholder {
  color: #bbb !important;
}

.formerize-placeholder {
  color: #bbb !important;
}

/* Image */

.image {
  border-radius: 2px;
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  border-radius: 2px;
  display: block;
}

.image.left, .image.right {
  max-width: 40%;
}

.image.left img, .image.right img {
  width: 100%;
}

.image.left {
  float: left;
  margin: 0 1.5rem 1rem 0;
  top: 0.25rem;
}

.image.right {
  float: right;
  margin: 0 0 1rem 1.5rem;
  top: 0.25rem;
}

.image.fit {
  display: block;
  margin: 0 0 2rem 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 0 0 3rem 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2rem 0;
  padding-left: 1.25rem;
}

ol li {
  padding-left: 0.25rem;
}

ul {
  list-style: disc;
  margin: 0 0 2rem 0;
  padding-left: 1rem;
}

ul li {
  padding-left: 0.5rem;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px;
  padding: 0.5rem 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1rem 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon:before {
  font-size: 2rem;
}

ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.actions li {
  display: inline-block;
  padding: 0 1rem 0 0;
  vertical-align: middle;
}

ul.actions li:last-child {
  padding-right: 0;
}

ul.actions.small li {
  padding: 0 0.5rem 0 0;
}

ul.actions.vertical li {
  display: block;
  padding: 1rem 0 0 0;
}

ul.actions.vertical li:first-child {
  padding-top: 0;
}

ul.actions.vertical li > * {
  margin-bottom: 0;
}

ul.actions.vertical.small li {
  padding: 0.5rem 0 0 0;
}

ul.actions.vertical.small li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  display: table;
  margin-left: -1rem;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1rem);
}

ul.actions.fit li {
  display: table-cell;
  padding: 0 0 0 1rem;
}

ul.actions.fit li > * {
  margin-bottom: 0;
}

ul.actions.fit.small {
  margin-left: -0.5rem;
  width: calc(100% + 0.5rem);
}

ul.actions.fit.small li {
  padding: 0 0 0 0.5rem;
}

@media screen and (max-width: 480px) {

  ul.actions {
    margin: 0 0 2rem 0;
  }

  ul.actions li {
    padding: 1rem 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }

  ul.actions li:first-child {
    padding-top: 0;
  }

  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }

  ul.actions li > *.icon:before {
    margin-left: -2rem;
  }

  ul.actions.small li {
    padding: 0.5rem 0 0 0;
  }

  ul.actions.small li:first-child {
    padding-top: 0;
  }

}

dl {
  margin: 0 0 2rem 0;
}

dl dt {
  display: block;
  font-weight: 700;
  margin: 0 0 1rem 0;
}

dl dd {
  margin-left: 2rem;
}

ul.alt li {
  border-top-color: rgba(144, 144, 144, 0.25);
}

/* Section/Article */

section.special, article.special {
  text-align: center;
}

header p {
  position: relative;
  margin: 0 0 1.5rem 0;
}

header h2 + p {
  font-size: 1.25rem;
  margin-top: -1rem;
}

header h3 + p {
  font-size: 1.1rem;
  margin-top: -0.8rem;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9rem;
  margin-top: -0.6rem;
}

header p {
  color: #bbb;
}

/* Footer */

#footer {
  padding: 4rem 0 0 0 ;
  position: absolute;
  bottom: 0px;
  background: #000;
  text-align: center;
  width: 100%;
  height: 190px;
}

#footer a {
  color: rgba(255, 255, 255, 0.8);
}

#footer a:hover {
  color: #FFF;
}

#footer .copyright {
  color: #bbb;
  font-size: 0.9rem;
  margin: 0 0 0 0;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 736px) {
  #footer {
    padding: 3rem 0 1rem 0 ;
  }
}

#footer > .icons {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#footer > .copyright {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}




.wrapper {
  padding: 6rem 0 3rem 0 ;
  position: relative;
  margin: 0;
}

.wrapper.style2 {
  padding: 2rem 0 1rem 0 !important;
  position: relative;
  margin: 0;
}

.wrapper > .inner {
  margin: 0 auto;
  width: 80rem;
}

.wrapper.style1 {
  background-color: #8a4680;
  color: #e2d1df;
}

.wrapper.style1 input, .wrapper.style1 select, .wrapper.style1 textarea {
  color: #ffffff;
}

.wrapper.style1 a {
  color: #ffffff;
}

.wrapper.style1 strong, .wrapper.style1 b {
  color: #ffffff;
}

.wrapper.style1 h1, .wrapper.style1 h2, .wrapper.style1 h3, .wrapper.style1 h4, .wrapper.style1 h5, .wrapper.style1 h6 {
  color: #ffffff;
}

.wrapper.style1 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 label {
  color: #ffffff;
}

.wrapper.style1 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 header p {
  color: #d0b5cc;
}

.wrapper.style1 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style1 table th {
  color: #ffffff;
}

.wrapper.style1 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style1 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style2 {
  background-color: #f2f2f2;
  color: #a6a6a6;
}

.wrapper.style2 a {
  color: #8a4680;
}

.wrapper.style2 strong, .wrapper.style2 b {
  color: #000;
}

.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
  color: #000;
}

.wrapper.style2 blockquote {
  border-left-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 code {
  background: rgba(0, 0, 0, 0.075);
  border-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 hr {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 .box {
  border-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 ul.alt li {
  border-top-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 header p {
  color: #999999;
}

.wrapper.style2 table tbody tr {
  border-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.075);
}

.wrapper.style2 table th {
  color: #000;
}

.wrapper.style2 table thead {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 table tfoot {
  border-top-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style2 table.alt tbody tr td {
  border-color: rgba(0, 0, 0, 0.15);
}

.wrapper.style3 {
  background-color: #444;
  color: #bfbfbf;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;
}

.wrapper.style3 a {
  color: #8a4680;
}

.wrapper.style3 strong, .wrapper.style3 b {
  color: #ffffff;
}

.wrapper.style3 h1, .wrapper.style3 h2, .wrapper.style3 h3, .wrapper.style3 h4, .wrapper.style3 h5, .wrapper.style3 h6 {
  color: #ffffff;
}

.wrapper.style3 blockquote {
  border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 code {
  background: rgba(255, 255, 255, 0.075);
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 hr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .box {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 ul.alt li {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 header p {
/color: #999999;
}

.wrapper.style3 table tbody tr {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 table th {
  color: #ffffff;
}

.wrapper.style3 table thead {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tfoot {
  border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table.alt tbody tr td {
  border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .inner {
  position: relative;
  z-index: 2;
}

.wrapper.style3 p {
  text-transform: uppercase;
  /* font-size: .75rem; */
  font-weight: 300;
  margin: 0 0 .5rem 0;
  padding: 0 0 1rem 0;
  letter-spacing: .25rem;
}

.wrapper.style3 p:after {
  content: '';
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}

.wrapper.style3:before {
  -moz-transition: opacity 3s ease;
  -webkit-transition: opacity 3s ease;
  -ms-transition: opacity 3s ease;
  transition: opacity 3s ease;
  -moz-transition-delay: 1.25s;
  -webkit-transition-delay: 1.25s;
  -ms-transition-delay: 1.25s;
  transition-delay: 1.25s;
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0.90);
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1280px) {

  /*.wrapper > .inner {*/
  /*width: 75rem;*/
  /*}*/
  .wrapper > .inner {
    width: 90%;
  }

  .wrapper.style3 {
    background-attachment: scroll;
  }

}

@media screen and (max-width: 980px) {

  .wrapper > .inner {
    width: 90%;
  }

  .wrapper.style3 {
    background-attachment: scroll;
  }

}

@media screen and (max-width: 736px) {

  .wrapper {
    padding: 3rem 0 1rem 0 ;
  }

}
